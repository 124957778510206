import React from 'react'
import NavBar from '../Components/NavBar'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

function DeleteAccountRequest() {
    const navigate = useNavigate()
    const handleClickSend =(event)=>{
        event.preventDefault()
        toast.success("We have received your request to delete your account")
        navigate("/")
    }
  return (
    <>
    <NavBar/>
    <section className="bg-white ">
    <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 ">Request For Deleting Account</h2>
        <p className="mb-8 lg:mb-16 font-light text-center text-gray-500  sm:text-xl">Please be aware that this action is permanent and cannot be undone. All your data, including [data like files, messages, etc.], will be permanently deleted.</p>
        <form onSubmit={handleClickSend} className="space-y-8">
         
          
            <div className="sm:col-span-2">
                <label for="message" className="block mb-2 text-sm font-medium text-gray-900">Reason</label>
                <textarea id="message" rows="6" className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-50" placeholder="lets why you want to delete account " required></textarea>
            </div>
            <p className="mb-8 lg:mb-16 font-light text-center text-red-500  sm:text-xl">Our agent will call you to verify your identity within 20 working days</p>
            <button type="submit" className="py-3 px-5 text-sm font-medium text-center rounded-lg bg-red-400 sm:w-fit hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-primary-300 ">Send </button>
        </form>
    </div>
  </section></>
  )
}

export default DeleteAccountRequest