
import { Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import IconButton from '../Components/IconStyle';
import NavBar from '../Components/NavBar';
import { setUserInfo } from '../store/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../util/TranslateFunction';
import { selectShopStatus } from '../store/shopStatusSlice';

const Details = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.user.userInfo);
  const order_type = useSelector((state) => state.cart.order_type);
  const info = useSelector(selectShopStatus);
  const [formData, setFormData] = useState({});
   const navigate = useNavigate();
  const [errors, setErrors] = useState({});
 useEffect(()=>{
  setFormData(userInfo)
 },[])

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate name
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim() || !emailRegex.test(formData.email)) {
      newErrors.email = 'Valid email is required';
      isValid = false;
    }

    // Validate phone number
    const phoneRegex = /^\d{10}$/;
    if (!formData.phoneNumber.trim() || !phoneRegex.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Valid 10-digit phone number is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // if(!info.isShopOpen){
      //   alert("we are closed right now")
      //   return
      // }
      // Perform actions with form data, e.g., send to server
      dispatch(setUserInfo({...formData}));
      if(order_type=== "delivery") {navigate("/details/address")}
      else{ if(order_type=== "takeaway") {navigate("/order-summary")}
      else{navigate("/")}}
      
    } else {
      console.log('Form has validation errors');
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (<>
      <NavBar />
        <div className="py-5 px-20">
        <button
          type="button"
          onClick={() => {window.history.back()}}
          className="focus:outline-none ml-5 px-3 text-white bg-[#eb6700] hover:bg-[#eb6700] h-[34px]  justify-center items-center font-medium rounded-[12px] text-sm p-1  "
        >
          <IconButton
            width="15px"
            height="15px"
            backgroundPosition="-728px 725px"
          />
        </button>
        <h2 className=" text-2xl text-[#14171A] flex-1 text-[26px] text-center mr-[45px] font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
          Your Details
        </h2>
      
      <p className="text-black font-epilogue text-base font-normal leading-5 pt-[40px]">We need the following details to proceed your order.</p>
    <form onSubmit={handleSubmit}>
  
      <div className="flex flex-col gap-[12px] pt-[30px]">
        <div className="flex flex-col gap-[6px]">
        <label  htmlFor="name" className="text-[#9796A1] font-epilogue text-base font-normal leading-normal">Full Name</label>
        <Input        type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange} placeholder="Full Name" />
        {errors.name && <span className=" text-red-400">{errors.name}</span>}

        </div>
        <div className="flex flex-col gap-[6px]">
        <label htmlFor="email" className="text-[#9796A1] font-epilogue text-base font-normal leading-normal">Mobile Number</label>
        <Input    type="text"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange} placeholder="1234567899"  />
            {errors.phoneNumber && (
          <span className="text-red-400">{errors.phoneNumber}</span>
        )}

        </div>
        <div className="flex flex-col gap-[6px]">
        <label className="text-[#9796A1] font-epilogue text-base font-normal leading-normal">Email Address</label>
        <Input    type="text"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange} placeholder="Email"   />
        {errors.email && <span className="text-red-400">{errors.email}</span>}

        </div>
        <div className="flex justify-center items-center">
      <button type="submit" className="flex justify-center items-center rounded-full my-[40px] bg-[#eb6700] py-[15px] px-[40px] text-white text-center font-syne text-base font-medium leading-normal">
          {translate("next")}
</button>
</div>
   
      </div>

    </form>
    </div>
    </>
  );
};

export default Details;
