import React, { useState } from 'react';

const Loader = ({ imageUrl, alt }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
   <div className='h-full w-full relative'>
      {loading && (
      
        <div className="absolute h-full w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      
        <div className="animate-pulse h-full w-full  bg-[#c1c1c1] "></div>
      </div>
      )}

      <img
        src={imageUrl}
        alt={alt}
        className={`w-full h-full object-cover ${loading ? 'hidden' : 'block'}`}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default Loader;
