import React from "react";

import { ImageUrl } from "../firebaseConfig";
import Loader from "./ImageLoader";

function CategoryCard({ item }) {
  return (
    <>
      {item.imageDownloadToken ? (
        <div className="shadow-lg cursor-pointer">
          <div className=" h-36 overflow-hidden">
              <Loader imageUrl={ImageUrl+item.imageDownloadToken} alt="unable to load file in ourMenu"/>       
          </div>
          <div className="px-6 py-4 bg-[#eeeeee] ">
            <h1 className=" font-semibold text-black w-full text-center uppercase">
              {item.name}
            </h1>
          </div>
        </div>
      ) : (
        <div className="px-6 py-4 bg-[#eeeeee] shadow-lg h-full flex items-center cursor-pointer ">
          <h1 className=" font-semibold text-black w-full text-center md:text-xl uppercase">
            {item.name}
          </h1>
        </div>
      )}
    </>
  );
}

export default CategoryCard;
