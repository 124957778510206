import React, { useEffect, useState } from 'react';
import { ShoppingBagIcon } from '@heroicons/react/24/outline';
import { useSelector } from 'react-redux';
function BadgeTag() {
const cartItems = useSelector(state => state.cart.items);
const [numberOfItems, setNumberOfItems]= useState(0)
useEffect(()=>{
  let count = 0;
  cartItems.map((item)=>{
    count = count+ item.quantity;
    return null
  })
setNumberOfItems(count)
},[cartItems])
  return (<>
  <div>
  <ShoppingBagIcon className="h-6 w-6 text-black" aria-hidden="true" />

  </div>
  <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-black bg-red-400 border-2 border-white rounded-full -top-2 -end-2 ">{numberOfItems}</div>
  </>

  )
}
export default BadgeTag