
// import { useEffect, useState } from 'react';
// import { ref, onValue, query, orderByChild, equalTo } from 'firebase/database';
// import { rdb } from '../firebaseConfig'; 
//  // Import your Firebase configuration

// const useCategories = () => {
//   const [categories, setCategories] = useState([]);

//   useEffect(() => {
// // Use your Firebase app instance here

//     const categoriesRef = ref(rdb, 'categories');
//     const q = query(categoriesRef, orderByChild('availablity'), equalTo(true));

//     const unsubscribe = onValue(q, (snapshot) => {
//       const categoriesData = snapshot.val();
//       if (categoriesData) {
//         // Convert the categories data into an array
//         const categoriesArray = Object.keys(categoriesData).map((categoryId) => ({
//           id: categoryId,
//           ...categoriesData[categoryId],
//         }));
//         setCategories(categoriesArray);
//       } else {
//         setCategories([]);
//       }
//     });

//     // Cleanup function to unsubscribe when the component unmounts
//     return () => {
//         unsubscribe();}
//   }, []);

//   return categories;
// };

// export default useCategories;


import { useEffect, useState } from 'react';
import { ref, onValue, off } from 'firebase/database';
import { rdb } from '../firebaseConfig'; 
 // Import your Firebase configuration

const useCategories = () => {
 

  const [categoryData, setCategoryData] = useState([]);
 
  
  useEffect(() => {
    const fetchData = () => {
      try {
        const categoriesRef = ref(rdb, 'categories');

        // Listen for changes in the 'categories' node and fetch data once
        onValue(categoriesRef, (snapshot) => {
          const categories = snapshot.val();
          if (categories) {
            const categoryArray = Object.entries(categories).map(([categoryId, category]) => {
              return { id: categoryId, ...category, products: [] }; // Initialize products array
            });
            setCategoryData(categoryArray);
          //   fetchProductDataForCategories(categoryArray);
          } else {
            setCategoryData([]);
          }
        }, );
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

   
    fetchData();

    // Cleanup function to remove the listener when component unmounts
    return () => {
      const categoriesRef = ref(rdb, 'categories');
      off(categoriesRef); 
    };
  }, []);

  return categoryData;
};

export default useCategories;
