import whiteLogo from '../Assets/logo/companyLogoWhite.png'
import companyLogoBlack from '../Assets/logo/companyLogoBlack.png'

import signupImg from  '../Assets/Images/signup-img.png';
import { Input} from 'antd';
import {useNavigate} from "react-router-dom";

import IconButton from '../Components/IconStyle';

function Login() {
  const navigate = useNavigate();

    return (
      <>
        <div className="md:hidden bg-[#eeeeee] flex justify-center items-center p-[70px] rounded-b-[50%]">
          <img src={companyLogoBlack} width='200px' alt="_logo" />
        </div>
        <div className='flex flex-row'>
          <div style={{backgroundImage: `url(${signupImg})`}} className='w-[50%] bg-no-repeat bg-cover md:flex justify-center hidden '>
            <div><img src={whiteLogo} alt="logo" className='pt-[41px] lg:w-[263px]'/></div>
          </div>
        <div className="m-auto flex flex-col justify-center items-center md:px-[101px]">
        <h2 className='text-[#14171A] text-center font-syne text-2xl font-bold leading-normal md:pt-[43px]'>Login</h2>
        <div className="flex flex-col gap-[47px] pt-[30px]">
        <div className="flex flex-col gap-[6px]">
        <label className="text-[#9796A1] font-epilogue text-base font-normal leading-normal">Email</label>
        <Input className='rounded-[10px] border border-solid border-[#EEE] h-[46px] w-full min-w-[220px] md:min-w-[327px] bg-white shadow-[15px_20px_45px_0_rgba(233,233,233,0.25)]' placeholder="Email" type="email" />
        </div>
        <div className="flex flex-col gap-[6px]">
        <label className="text-[#9796A1] font-epilogue text-base font-normal leading-normal">Password</label>
        <Input className='rounded-[10px] border border-solid border-[#EEE] h-[46px] w-full min-w-[220px] md:min-w-[327px] bg-white shadow-[15px_20px_45px_0_rgba(233,233,233,0.25)]' type="password" suffix={<IconButton
            width="15px"
            height="15px"
            backgroundPosition="-131px 417px"
          />} />
        </div>
        </div>
        <div className="flex justify-center items-center flex-col gap-[18px]">
      <button onClick={() => {navigate("/menus")}} className="flex justify-center items-center rounded-full mt-[40px] bg-[#eb6700] py-[12px] px-[40px] text-white text-center font-syne text-base font-medium leading-normal">
          Login
        </button>
        <p className="text-[#78828A] text-justify font-epilogue text-xs font-medium leading-5 mb-[40px]">Don’t have an Account? <span onClick={() => {navigate("/signUp")}} className='text-[#eb6700]'>Sign Up</span></p>
        </div>
        </div>
        </div>
      </>
    );
}

export default Login;