import React, { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { addToCart } from "../store/cartSlice";
import { generateUidWithTime } from "../util/GenerateUid";

const OptionsForm = ({ item, closeModal }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(1);
  const [errorMessages, setErrorMessages] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOptionSelect = (group, option) => {
    const groupId = group.id;
    const group_name = group.name;
    const optionId = option.id;
    const required = group.required;
    const { name, price } = option;

    const existingAnswerIndex = selectedOptions.findIndex(
      (answer) => answer.groupId === groupId
    );
    if (existingAnswerIndex === -1) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        {
          groupId,

          group_name,

          selectedOptionIds: [optionId],

          selectedOptions: [{ name, price, quantity: 1 }],
        },
      ]);
    } else {
      if (required) {
        setSelectedOptions((prevSelectedOptions) => {
          const updatedSelectedOptions = [...prevSelectedOptions];
          const existingOptionIndex =
            updatedSelectedOptions[
              existingAnswerIndex
            ].selectedOptionIds.indexOf(optionId);
          if (existingOptionIndex === -1) {
            updatedSelectedOptions[existingAnswerIndex].selectedOptionIds = [
              optionId,
            ];

            updatedSelectedOptions[existingAnswerIndex].selectedOptions = [
              { name, price, quantity: 1 },
            ];
          } else {
            updatedSelectedOptions[existingAnswerIndex].selectedOptionIds.splice(
              existingOptionIndex,
              1
            );
            updatedSelectedOptions[existingAnswerIndex].selectedOptions.splice(
              existingOptionIndex,
              1
            );
          }
          return updatedSelectedOptions;
        });
        return;
      }
      setSelectedOptions((prevSelectedOptions) => {
        const updatedSelectedOptions = [...prevSelectedOptions];
        const existingOptionIndex =
          updatedSelectedOptions[existingAnswerIndex].selectedOptionIds.indexOf(
            optionId
          );
        if (existingOptionIndex === -1) {
          updatedSelectedOptions[existingAnswerIndex].selectedOptionIds.push(
            optionId
          );

          updatedSelectedOptions[existingAnswerIndex].selectedOptions.push({
            name,
            price,
            quantity: 1,
          });
        } else {
          updatedSelectedOptions[existingAnswerIndex].selectedOptionIds.splice(
            existingOptionIndex,
            1
          );
          updatedSelectedOptions[existingAnswerIndex].selectedOptions.splice(
            existingOptionIndex,
            1
          );
        }
        return updatedSelectedOptions;
      });
    }
    setErrorMessages([]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const requiredGroups = item.groups.filter((group) => group.required);
    const answeredGroups = selectedOptions.map((answer) => answer.groupId);
    const missingRequiredGroups = requiredGroups.filter(
      (group) => !answeredGroups.includes(group.id)
    );

    if (missingRequiredGroups.length > 0) {
      setErrorMessages(
        missingRequiredGroups.map((group) => `Please Select : ${group.name}`)
      );
      toast.error(`Please Select Required Group`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    // Proceed with form submission
    const { id, name, price } = item;
    const cartItem = {
      name,
      id,
      price,
      cartItemKey: generateUidWithTime(),
      quantity,
      options: selectedOptions,
    };
    dispatch(addToCart(cartItem));
    closeModal();
    setErrorMessages([]);
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className=" max-h-64 overflow-y-auto overflow-x-hidden">
          {/* Rendering groups */}
          {item.groups.map((group) => (
            <div key={group.id}>
              <h3 className=" font-semibold my-2">
                {group.name}{" "}
                {group.required ? (
                  <span className="text-xs text-gray-400">(Required)</span>
                ) : (
                  ""
                )}{" "}
              </h3>

              <ul>
                {group.options &&
                  group?.options.map((option) => (
                    <li
                      key={option.id}
                      className="flex justify-between shadow-sm p-2"
                    >
                      <label className="flex gap-2 items-center ">
                        <input
                          type={group.required ? "radio" : "checkbox"}
                          className=" cursor-pointer w-4 h-4  bg-gray-100 border-gray-300 rounded   dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                          name={`group_${group.id}`}
                          disabled={option.is_out_of_stock}
                          value={option.id}
                          onChange={() => handleOptionSelect(group, option)}
                        />

                        {option.name}
                      </label>
                      {option.price > 0 && (
                        <span className="text-sm text-gray-400">
                          +{option.price} kr
                        </span>
                      )}
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        </div>
        {errorMessages.length > 0 && (
          <div style={{ color: "red" }}>
            {errorMessages.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
          </div>
        )}
        {/* Quantity and submit button */}
        <div className="flex justify-between p-5">
          <div className="flex gap-2 justify-center items-center">
            <div
              className="text-[#eb6700] border border-[#eb6700] pb-[0px] px-2  select-none cursor-pointer"
              onClick={() =>
                setQuantity((prev) => (prev > 1 ? quantity - 1 : 1))
              }
            >
              <div className="">-</div>
            </div>
            <span className="text-[#5B5B5E] font-epilogue text-base font-normal leading-6">
              {quantity}
            </span>{" "}
            <div
              className="text-white bg-[#eb6700] pt-[0px] pb-[2px] px-2 select-none cursor-pointer flex justify-center items-center"
              onClick={() => setQuantity((prev) => prev + 1)}
            >
              <div className="">+</div>
            </div>
          </div>
          <button
            className="bg-[#eb6700] text-white p-1 rounded-sm px-3"
            type="submit"
          >
            Add
          </button>
        </div>
      </form>
    </div>
  );
};

export default OptionsForm;
