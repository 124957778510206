// cartSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { generateUidWithTime } from '../util/GenerateUid';
import cartItemPrice from "../util/cartItmePrice";



const calculateVAT = (cartItems) => {
    
  let subtotal = 0
  cartItems.forEach((item)=>{
    subtotal += cartItemPrice(item);
  })


  const vat = subtotal - subtotal * (1 / 1.12); // Swedish rounding to the nearest 5 cents
  // const vat = Math.round(subtotal *  ); // Swedish rounding to the nearest 5 cents
  return { subtotal, vat }; // Return both subtotal and VAT
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: { items: [], trackingId:generateUidWithTime(), order_type:"takeaway",subTotal:0, VAT:0 },
  reducers: {
    addToCart: (state, action) => {
     
      
      const existingItem = state.items.find(item => item.cartItemKey === action.payload.cartItemKey);
   
      
      if (existingItem) {
        // If the item already exists, increase the quantity
        existingItem.quantity += action.payload.quantity || 1;
      } else {
        // If the item is not in the cart, add it
        state.items.push(action.payload);
      }
        toast.success('Added To Cart', {
        position:"top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
       
        });
        state.subTotal=calculateVAT(state.items).subtotal;
        state.VAT = calculateVAT(state.items).vat;
      console.log();
    },
    removeFromCart: (state, action) => {
      state.items = state.items.filter(item => item.cartItemKey !== action.payload.cartItemKey);
      state.subTotal=calculateVAT(state.items).subtotal;
      state.VAT = calculateVAT(state.items).vat;
    },
    changeQuantity: (state, action) => {
      
      const { id, quantity } = action.payload;
     

      const existingItem = state.items.find(item => item.cartItemKey === id);

      if (existingItem) {
        // If the item exists, update its quantity
        existingItem.quantity = quantity;
      }
      state.subTotal=calculateVAT(state.items).subtotal;
      state.VAT = calculateVAT(state.items).vat;
    },
    clearCart: state => {
      state.items = [];
      state.subTotal=calculateVAT(state.items).subtotal;
      state.VAT = calculateVAT(state.items).vat;
    },
    setOrderType: (state, action) => {
      state.order_type = action.payload;
    },
  },
});

export const { addToCart, removeFromCart, changeQuantity, clearCart, setOrderType } = cartSlice.actions;
export default cartSlice.reducer;





// // cartSlice.js
// import { createSlice } from '@reduxjs/toolkit';
// import { toast } from 'react-toastify';

// const cartSlice = createSlice({
//   name: 'cart',
//   initialState: { items: [] },
//   reducers: {
//     addToCart: (state, action) => {
      
//       const existingItem = state.items.find(item => item.id === action.payload.id);
//       if (existingItem) {
//         // If the item already exists, increase the quantity
//         existingItem.quantity += action.payload.quantity || 1;
//       } else {
//         // If the item is not in the cart, add it
//         state.items.push(action.payload);
//       }
//       toast.success('Added To Cart', {
//         position: "top-center",
//         autoClose: 3000,
//         hideProgressBar: false,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: undefined,
//         theme: "light",
       
//         });
//     },
//     removeFromCart: (state, action) => {
//       state.items = state.items.filter(item => item.id !== action.payload.id);
//     },
//     changeQuantity: (state, action) => {
//       const { id, quantity } = action.payload;
//       const existingItem = state.items.find(item => item.id === id);

//       if (existingItem) {
//         // If the item exists, update its quantity
//         existingItem.quantity = quantity;
//       }
//     },
//     clearCart: state => {
//       state.items = [];
//     },
//   },
// });

// export const { addToCart, removeFromCart, changeQuantity, clearCart } = cartSlice.actions;
// export default cartSlice.reducer;
