
import { combineReducers } from 'redux';
import userReducer from './userSlice';
import cartReducer from './cartSlice';
import productsReducer from './productSlice'
import shopStatusReducer from './shopStatusSlice';


const rootReducer = combineReducers({
  user: userReducer,
  cart: cartReducer,
  products: productsReducer,
  shopStatus: shopStatusReducer,
});

export default rootReducer;
