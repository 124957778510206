import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import BlacklogoImage from "../Assets/logo/companyLogoBlack.png";
import { Link, useNavigate } from "react-router-dom";
import BadgeTag from "./Badge";
import { useTranslation } from "react-i18next";
import { getDownloadURL, ref } from "firebase/storage";
import { Storage, rdb } from "../firebaseConfig";
import Drawer from "./Drawer";
import { get, ref as rdbRef } from "firebase/database";

const navigation = [{ name: "Home", href: "/", current: true }];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  
  const [isOpen, setIsOpen] = useState(false);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState(BlacklogoImage);
  const [Email, setEmail] = useState();
  const [PhoneNumber, setPhoneNumber] = useState();
  const [orgNumber, setOrgNumber] = useState();
  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    const fetchData = async () => {
      // Create a reference to the file we want to download

      const starsRef = ref(Storage, "LogoImages/lightLogo");

      // Get the download URL
      getDownloadURL(starsRef)
        .then((url) => {
          setLogoPreviewUrl(url);
          // Insert url into an <img> tag to "download"
        })
        .catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case "storage/object-not-found":
              console.log("Not Found");
              break;
            case "storage/unauthorized":
              // User doesn't have permission to access the object
              break;
            case "storage/canceled":
              // User canceled the upload
              break;

            // ...

            case "storage/unknown":
              // Unknown error occurred, inspect the server response
              console.log("jjjjjjjjjjjjjjjjjjjjjjj");

              break;
          }
        });
    };
    const getStoredValue = async () => {
      get(rdbRef(rdb, "Restaurant_Info")).then((snapshot) => {
          if (snapshot.exists()) {
            console.log(snapshot.val());
           const data = snapshot.val();
          
                setPhoneNumber(data.phone);
                setOrgNumber(data.orgNumber);
               
                setEmail(data.email);
              
          } else {
            console.log("No data available");
          }
        }).catch((error) => {
          console.error(error);
        });
     
    };
    fetchData();
    getStoredValue()
  }, []);
  return (
    <>
      <Disclosure as="nav">
        {({ open }) => (
          <>
            <div className="mx-auto   px-2 sm:px-6 lg:px-8">
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                  <button
                    className="  rounded px-4 py-1"
                    onClick={() => setIsOpen(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                      />
                    </svg>
                  </button>
                </div>
                <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                  <div
                    className="flex flex-shrink-0 items-center"
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    <img
                      className="h-16 w-auto p-2"
                      src={logoPreviewUrl}
                      alt="Your Company"
                    />
                  </div>
                </div>
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
                  onClick={() => navigate("/cart")}
                >
                  <button
                    type="button"
                    className="relative rounded-full p-1 text-gray-400   focus:ring-offset-2 focus:ring-offset-gray-400"
                  >
                    {/* shoping incon in BadgeTag  */}
                    <BadgeTag />
                  </button>

                  {/* Profile dropdown */}
                  {/*<IconButton />*/}
                 
                </div>
                <div className="hidden md:block ">
                <button
                    className="  rounded px-4 py-1"
                    onClick={() => setIsOpen(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 text-white"
                        : "text-gray-900 hover:bg-gray-700 hover:text-white",
                      "block rounded-md px-3 py-2 text-base font-medium"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Drawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <div>
            <Menu>
            {({ open }) => (
              <>
              
               
                  
                  <Menu.Button className="inline-flex  w-full align-middle ">
                   
                    <div className="flex gap-2 items-center">
                    <svg
                      width="15"
                      height="15"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g opacity="0.8">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.0007 2.50065C5.85852 2.50065 2.50065 5.85852 2.50065 10.0007C2.50065 14.1428 5.85852 17.5007 10.0007 17.5007C14.1428 17.5007 17.5007 14.1428 17.5007 10.0007C17.5007 5.85852 14.1428 2.50065 10.0007 2.50065ZM0.833984 10.0007C0.833984 4.93804 4.93804 0.833984 10.0007 0.833984C15.0633 0.833984 19.1673 4.93804 19.1673 10.0007C19.1673 15.0633 15.0633 19.1673 10.0007 19.1673C4.93804 19.1673 0.833984 15.0633 0.833984 10.0007Z"
                          fill="#eb6700"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M0.833984 9.99935C0.833984 9.53911 1.20708 9.16602 1.66732 9.16602H18.334C18.7942 9.16602 19.1673 9.53911 19.1673 9.99935C19.1673 10.4596 18.7942 10.8327 18.334 10.8327H1.66732C1.20708 10.8327 0.833984 10.4596 0.833984 9.99935Z"
                          fill="#eb6700"
                        ></path>
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.50084 10.0008C7.55796 12.5632 8.4392 15.0301 10.0006 17.0418C11.5621 15.0301 12.4433 12.5632 12.5005 10.0008C12.4433 7.43845 11.5621 4.97153 10.0007 2.95982C8.4392 4.97153 7.55796 7.43845 7.50084 10.0008ZM10.0007 1.66749L9.38536 1.10547C7.16473 3.53658 5.90275 6.69153 5.83417 9.98346C5.83392 9.99503 5.83392 10.0066 5.83417 10.0182C5.90275 13.3101 7.16473 16.4651 9.38536 18.8962C9.54325 19.069 9.76655 19.1675 10.0007 19.1675C10.2348 19.1675 10.4581 19.069 10.6159 18.8962C12.8366 16.4651 14.0986 13.3101 14.1671 10.0182C14.1674 10.0066 14.1674 9.99503 14.1671 9.98346C14.0986 6.69153 12.8366 3.53658 10.6159 1.10547L10.0007 1.66749Z"
                          fill="#eb6700"
                        ></path>
                      </g>
                    </svg>
                    <span className="text-xl pb-2"> {i18n.language}</span>

                    </div>
                  
                  </Menu.Button>
             

                <Transition
                  show={open}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute left-0 mt-2 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } block w-full text-left px-4 py-2 text-sm`}
                            onClick={() => handleLanguageChange("en")}
                          >
                            English
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-gray-100 text-gray-900"
                                : "text-gray-700"
                            } block w-full text-left px-4 py-2 text-sm`}
                            onClick={() => handleLanguageChange("sv")}
                          >
                            Swedish
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
          <h1 className="text-xl text-[#eb6700]">Kontakta Oss</h1> 
          <p>Email: {Email}</p>
          <p>Tel:{PhoneNumber}</p>
          <p>Org-Number: {orgNumber}</p>{" "}
        <div>
        <h1 className="text-xl text-[#eb6700] mt-5">Quick Links</h1> 

        </div>
          <Link className="underline text-xs" to="/privacy-policy">
            Privacy Policy
          </Link>
          <br/>
          <Link className="underline text-xs" to="/cookies-policy">
            Cookies Policy
          </Link>
          <br/>
          <Link className="underline text-xs" to="/purchase-policy">
          Köpvillkor policy
          </Link>
          <br/>
          <Link className="underline text-xs" to="/terms-and-conditions">
          Terms & Conditions
          </Link>
          <br/>
          <Link className="underline text-xs" to="/delete-account-request">
          Request to delete account
          </Link>
        </div>
      </Drawer>
    </>
  );
}
