import React, { useState, useEffect } from 'react';
import { rdb } from '../firebaseConfig';
import { get, ref as rdbRef } from 'firebase/database';
import defaultImage from "../Assets/Images/LandingBannerDefault.png"
import NavBar from '../Components/NavBar';
import { useNavigate } from 'react-router-dom';
import {ArrowLongRightIcon} from "@heroicons/react/24/solid";
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { setOrderType } from '../store/cartSlice';
import { setDeliveryCharge } from '../store/userSlice';



// Define the GetTest component
const LandingPage = () => {

  const [description, setDescription] = useState('');
  const [imageUrl, setImageUrl] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation()



  useEffect(() => {
    
    const fetchData = async () => {
    
      const imageRef = rdbRef(rdb, 'landingBanner'); 
      const snapshot = await get(imageRef);

      if (snapshot.exists()) {
        const imageData = snapshot.val();
        setDescription(imageData.description);
        setImageUrl(imageData.imageUrl);
      }
    };

    fetchData();
  }, []); 
  const handleClick = (orderType) => {
      if(orderType==="takeaway") dispatch(setDeliveryCharge(0))
       dispatch(setOrderType(orderType));

        navigate("/menus");
      };

  return (
    <>
   
     <NavBar />

    
    <div className=" relative h-screen">
      {imageUrl && (
        <div className=" h-full  mx-auto overflow-hidden bg-gray-800 rounded-lg shadow-lg">
          <img 
            src={imageUrl} 
            alt="Preview" 
            onError={(e) => {
              e.target.src =defaultImage ; // Replace with the URL for your default image
            }}
            className="object-cover w-full h-full"
          />
          <div className="absolute   mx-auto inset-0 h-full bg-black opacity-50"></div>
          <div className="absolute   mx-auto inset-0 flex flex-col gap-10 items-center justify-center">
            <div className="text-white text-center whitespace-pre-line">
              <p className="text-4xl font-bold text-start">{description}</p> 
            </div>
            <button
          type="button"
          onClick={() => {
            handleClick("takeaway");
          }}
          className=" flex text-white bg-[#eb6700] hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:focus:ring-yellow-900"
        >
         {t('takeaway')}
          <ArrowLongRightIcon className="h-5" />
        </button>
        <button
          type="button"
          onClick={() => {
            handleClick("delivery");
          }}
          className=" flex text-white bg-[#eb6700] hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:focus:ring-yellow-900"
        >
         {t('delivery')}
          <ArrowLongRightIcon className="h-5" />
        </button>
          </div>
        </div>
      )}
       {!imageUrl && (
        <div className="h-full  mx-auto overflow-hidden bg-gray-800 rounded-lg shadow-lg">
          <img 
            src={defaultImage} 
            alt="Preview" 

            className="object-cover w-full h-full"
          />
          <div className="absolute   mx-auto inset-0 h-full bg-black opacity-50"></div>
          <div className="absolute   mx-auto inset-0 flex flex-col gap-10 items-center justify-center">
            <div className="text-white text-center whitespace-pre-line">
              <p className="text-4xl font-bold text-start">{description}</p> 
            </div>
          <div className='flex gap-5'>     <button
          type="button"
          onClick={() => {
            handleClick("takeaway");
          }}
          className=" flex text-white bg-[#eb6700] hover:bg-[#ff8120] focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:focus:ring-yellow-900"
        >
         {t('takeAway')}
          
        </button>
        <button
          type="button"
          onClick={() => {
            handleClick("delivery");
          }}
          className=" flex text-white bg-[#eb6700] hover:bg-[#ff8120] focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:focus:ring-yellow-900"
        >
         {t('delivery')}
          
        </button></div>
       
          </div>
        </div>
      )}
      
    </div>
  
    </>
  );
};

// Export the GetTest component
export default LandingPage;

