import { onValue, ref } from "firebase/database";
import React, { useEffect, useState } from "react";
import { rdb } from "../../firebaseConfig";
import { useLocation, useNavigate } from "react-router-dom";
import useCategories from "../../FirebaseMethods/GetCatogories";
import MenuItemCard from "../../Components/MenuItemCard";
import IconButton from "../../Components/IconStyle";
import OurMenusBar from "../../Components/OurMenusBar";
import NavBar from "../../Components/NavBar";


function ProductsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const categoryId = location.pathname.replace(/%20/g, " ").replace(/\//g, "");
  const categories = useCategories();
  const [products, setProduct] = useState([]);


  const getSnapshot = async (ref) => {
    const snapshot = await new Promise((resolve, reject) => {
      onValue(
        ref,
        (snapshot) => {
          resolve(snapshot);
        },
        {
          onlyOnce: true,
        }
      );
    });
    return snapshot;
  };
  const fetchProductDataForCategories = async (productIds) => {
    try {
      const productData = await fetchProductDataForCategory(productIds);
      const updatedProducts = await fetchGroupDataForProducts(productData);
      setProduct(updatedProducts);
    } catch (error) {
      console.error("Error fetching product data for categories:", error);
    }
  };
  const fetchProductDataForCategory = async (productIds) => {
    try {
      const productsPromises = productIds.map((productId) => {
        const productRef = ref(rdb, `products/${productId}`);
        return getSnapshot(productRef);
      });
      const productSnapshots = await Promise.all(productsPromises);

      return productSnapshots.map((snapshot) =>   {return {id:snapshot.key, ...snapshot.val()}});
    } catch (error) {
      console.error("Error fetching product data:", error);
      return [];
    }
  };
  const fetchGroupDataForProducts = async (products) => {
    try {
      const updatedProducts = await Promise.all(
        products.map(async (product) => {
          
          const groupIds = product.groupIds || [];
          const groupData = await fetchGroupData(groupIds);
          return { ...product, groups: groupData };
        })
      );
      return updatedProducts;
    } catch (error) {
      console.error("Error fetching group data for products:", error);
      return [];
    }
  };

  const fetchGroupData = async (groupIds) => {
    try {
      const groupsPromises = groupIds.map((groupId) => {
        const groupRef = ref(rdb, `groups/${groupId}`);
      
        return getSnapshot(groupRef);
      });
      const groupSnapshots = await Promise.all(groupsPromises);
      return groupSnapshots.map((snapshot) =>{return {id: snapshot.key ,...snapshot.val()}});
    } catch (error) {
      console.error("Error fetching group data:", error);
      return [];
    }
  };
 
  useEffect(() => {
    const productIds = categories.filter((item) => item.id === categoryId)[0]
      ?.productIds;

    if (productIds) fetchProductDataForCategories(productIds);
  }, [categories, categoryId]);


 




  return (
    <>
      <NavBar />
      <OurMenusBar />
      <div className="flex mt-[39px]">
        <button
          type="button"
          onClick={() => {
            navigate("/menus");
          }}
          className="focus:outline-none ml-5 px-2 text-white bg-[#eb6700] hover:bg-[#eb6700] h-[28px]  font-medium rounded-lg text-sm p-1  "
        >
          <IconButton
            width="15px"
            height="15px"
            backgroundPosition="-728px 725px"
          />
        </button>
      
      </div>

      {/* {Object.keys(products).length==0?<p className=" font-bold mt-5 text-3xl text-center ">No Products</p>:null}*/}
      <div className="grid  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-4 p-3 mt-4 text-white">
        {products?.map((item, index) => (
          item.active && <MenuItemCard item={item} key={index} />

        ))}
      </div>
    </>
  );
}

export default ProductsPage;
