import React, { useEffect, useState } from "react";
import MapComponent from "./GoogleMap";
import { toast } from "react-toastify";
import {useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAddressToState, setDeliveryCharge } from "../store/userSlice";


const MapModal = ({ open, setOpen, address, setAddress, mapCenter }) => {
  const [loading, setLoading] = useState(false);
  const [distance, setDistance] = useState(null);
  const [deliveryFee, setDeliveryFee] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  },[open]);

  function calculateDeliveryFee(distanceInKm) {
    // Define delivery fee ranges
    const distance = parseFloat(distanceInKm.replace(/[^\d.]/g, ""));
    const feeRanges = [
      { range: [0, 2], fee: 30, description: "0-2 km: $10" },
      { range: [2, 4], fee: 40, description: "6-10 km: $20" },
      { range: [4, 6.3], fee: 50, description: "10-15 km: $30" },
    ];

    // Check if distance is within any range
    for (const range of feeRanges) {
      if (distance >= range.range[0] && distance <= range.range[1]) {
        return { fee: range.fee };
      }
    }

    // If distance is above all defined ranges
    return { fee: null, message: "Out of delivery area" };
  }

  useEffect(() => {
    if (!distance) return;
    let deliveryResult = calculateDeliveryFee(distance);
    if (deliveryResult.fee !== null && deliveryResult.fee !== undefined) {
      setDeliveryFee(deliveryResult.fee);
    } else {
      toast.error("Out of delivery area", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setDeliveryFee(deliveryResult.fee);
    }
  }, [distance, address]);

  const handleCancel = () => {
    setOpen(false);
    setAddress("");
    setDistance(null);
    setDeliveryFee(null);
  };
  const handleNext =()=>{
    setOpen(false)
    
    dispatch(setAddressToState(address))
    dispatch(setDeliveryCharge(deliveryFee))
    document.body.style.overflowY = "scroll";
    navigate("/order-summary")
  }

  return (
    <div
      id="select-modal"
      tabIndex="-1"
      aria-hidden={!open}
      className={`overflow-y-auto overflow-x-hidden fixed  bg-[#0006] flex justify-center align-middle  z-50  items-center w-full inset-0 h-[calc(100%-1rem)] max-h-full ${
        open ? "" : "hidden"
      }`}
    >
      <div className="relative p-2 w-full max-w-md max-h-full ">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex  items-center justify-between p-4 md:p-4 border-b rounded-t dark:border-gray-600">
            <div>
              <h3 className="text-sm font-semibold text-gray-900 ">
                {address}
              </h3>
              {deliveryFee && (
                <h3 className="text-sm font-semibold text-gray-900 ">
                  DELIVERY FEE: {"kr " + deliveryFee}
                </h3>
              )}
              <h3 className="text-xs font-semibold text-gray-900 ">
                Distance from restaurant: {distance}
              </h3>
            </div>
            <div className="flex flex-col gap-3 text-center justify-center align-middle items-end">
              <button
                onClick={handleCancel}
                type="button"
                className="text-gray-400 bg-transparent p-3 "
              >
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              {deliveryFee && address && <button type="button" onClick={handleNext} className="hover:bg-[#fd9443] bg-[#eb6700] px-3 py-1 font-semibold rounded-md text-white">Next</button>}
            </div>
          </div>
          <div className="p-4 text-black md:p-5">
            {open && (
              <MapComponent
                mapCenter={mapCenter}
                setAddress={setAddress}
                distance={distance}
                setDistance={setDistance}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapModal;
