import React from 'react';
import NavBar from '../Components/NavBar';

const CookiesPolicy = () => {
  return (
    <>
    <NavBar />
  
  <p
    style={{
      marginTop: "0pt",
      marginBottom: "0pt",
      lineHeight: "normal",
      fontSize: "13.5pt",
      backgroundColor: "#ffffff"
    }}
  >
    <strong>
      <span style={{ fontFamily: '"Times New Roman"' }}>Vad är cookies?</span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "14pt",
      marginBottom: "14pt",
      lineHeight: "normal",
      fontSize: "10.5pt",
      backgroundColor: "#ffffff"
    }}
  >
    <span style={{ fontFamily: "Arial" }}>
      Cookies är små filer som laddas ner till din enhet, till exempel, när du
      besöker vår online-beställning. Dom hjälper oss att ge dig en bättre
      användarupplevelse.
    </span>
  </p>
  <p
    style={{
      marginTop: "0pt",
      marginBottom: "0pt",
      lineHeight: "normal",
      fontSize: "13.5pt",
      backgroundColor: "#ffffff"
    }}
  >
    <strong>
      <span style={{ fontFamily: '"Times New Roman"' }}>
        Hur vi använder cookies
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "14pt",
      marginBottom: "14pt",
      lineHeight: "normal",
      fontSize: "10.5pt",
      backgroundColor: "#ffffff"
    }}
  >
    <span style={{ fontFamily: "Arial" }}>
      Vi använder cookies för att du ska kunna navigera mellan sidor, hålla dig
      inloggad och till att komma ihåg dina inställningar. Vi använder dom för
      statistik och analyssyften så att vi bättre kan förstå vad du och andra
      kunder vill ha. Vi vill också använda cookies för marknadsföringsändamål
      så att relevanta annonser från oss kan visas när du besöker andra
      webbplatser.
    </span>
  </p>
  <p
    style={{
      marginTop: "0pt",
      marginBottom: "14pt",
      lineHeight: "normal",
      fontSize: "10.5pt",
      backgroundColor: "#ffffff"
    }}
  >
    <span style={{ fontFamily: "Arial" }}>
      Våra cookies är grupperade i följande kategorier:
    </span>
  </p>
  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "27.6pt",
        lineHeight: "normal",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ fontFamily: "Arial", fontSize: "10.5pt" }}>
        Nödvändiga cookies
      </span>
      <br />
      <span style={{ fontFamily: "Arial", fontSize: "10.5pt" }}>
        För att vår online-beställning ska fungera korrekt, t.ex. inloggning,
        varukorg, betalning, orderstatus. Utan dessa cookies kan vi inte
        tillhandahålla vår tjänst.
      </span>
    </li>
    <li
      style={{
        marginLeft: "27.6pt",
        lineHeight: "normal",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ fontFamily: "Arial", fontSize: "10.5pt" }}>
        Inställningar
      </span>
      <br />
      <span style={{ fontFamily: "Arial", fontSize: "10.5pt" }}>
        Kommer ihåg ditt land- och språkval, inloggning och varukorg längre än
        bara sessionen.
      </span>
    </li>
    <li
      style={{
        marginLeft: "27.6pt",
        lineHeight: "normal",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ fontFamily: "Arial", fontSize: "10.5pt" }}>
        Statistik &amp; analys
      </span>
      <br />
      <span style={{ fontFamily: "Arial", fontSize: "10.5pt" }}>
        Hjälper oss att utveckla och förbättra vår online-beställning. Data
        delas med Google.
      </span>
    </li>
    <li
      style={{
        marginLeft: "27.6pt",
        marginBottom: "14pt",
        lineHeight: "normal",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt",
        backgroundColor: "#ffffff"
      }}
    >
      <span style={{ fontFamily: "Arial", fontSize: "10.5pt" }}>
        Marknadsföring
      </span>
      <br />
      <span style={{ fontFamily: "Arial", fontSize: "10.5pt" }}>
        För att kunna visa reklam från oss när du besöker andra webbplatser
        (reklam visas ändå - men inte nödvändigtvis relevant). Data delas med
        tredje part som Google, Facebook, Instagram, Youtube.
      </span>
    </li>
  </ul>
  <p
    style={{
      marginTop: "0pt",
      marginBottom: "0pt",
      lineHeight: "normal",
      fontSize: "13.5pt",
      backgroundColor: "#ffffff"
    }}
  >
    <strong>
      <span style={{ fontFamily: '"Times New Roman"' }}>
        Ändringar av cookie policyn
      </span>
    </strong>
  </p>
  <p
    style={{
      marginTop: "14pt",
      marginBottom: "14pt",
      lineHeight: "normal",
      fontSize: "10.5pt",
      backgroundColor: "#ffffff"
    }}
  >
    <span style={{ fontFamily: "Arial" }}>
      Denna cookie policy kan ändras när som helst. Om den ändras ger vi dig
      möjligheten att se över dina cookie-inställningar igen.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>

</>


  );
};

export default CookiesPolicy;
