// shopStatusSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
 // Adjust the import path based on your project structure
import { rdb } from '../firebaseConfig';
import { equalTo, get, limitToFirst, orderByChild, query, ref } from 'firebase/database';


const getCurrentDay = () => {
  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const today = new Date().getDay();
  return days[today];
};
const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };
const getCurrentTime = () => {
  const now = new Date();
  const hours = now.getHours();
  const minutes = now.getMinutes();
  return `${hours}:${minutes}`;
};
const determineShopStatus = (shopTimeing, currentTime) => {  
    if (shopTimeing) {
    const openingTime = shopTimeing.open;
    const closingTime = shopTimeing.close;
    const bit = shopTimeing.Bit
    const currentTimeInMinutes = convertToMinutes(currentTime);
    const openingTimeInMinutes = convertToMinutes(openingTime);
    const closingTimeInMinutes = convertToMinutes(closingTime);
     if(!bit) return {isShopOpen:false, Bit:false}
    if (currentTimeInMinutes >= openingTimeInMinutes && currentTimeInMinutes <= closingTimeInMinutes ) {       
        return {isShopOpen:true, Bit:true};
    } else {
      return {isShopOpen:false, Bit:true};
    }
    } else {
      return 'No schedule available for today';
    }};
export const fetchShopStatus = createAsyncThunk('shopStatus/fetchStatus', async () => {
  try {
    const currentDay = getCurrentDay();
    const currentTime = getCurrentTime();


    // Query Firebase to get data for the current day
    const shopTimeingRef = ref(rdb, 'timing');
    const q = query(shopTimeingRef, orderByChild('day'), equalTo(currentDay), limitToFirst(1));
    const snapshot = await get(q);

    if (snapshot.exists()) {
        let todaySchedule
        const timingData = snapshot.val()

        // Filter out null values and keep only non-null entries
        const nonNullData = Object.values(timingData).filter((entry) => entry !== null);
  
        if (nonNullData.length > 0) {
          // Assuming you want the first entry if there are multiple for the same day
          todaySchedule = nonNullData[0];
          
        } else {
          console.log(`No timing data found for `);
        }
       
           
      const status = determineShopStatus(todaySchedule, currentTime);
      return status;
    } else {
      return 'Error fetching data from Firebase';
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
});

const initialState = {
  status: 'Loading...',
};

const shopStatusSlice = createSlice({
  name: 'shopStatus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopStatus.fulfilled, (state, action) => {
        state.status = action.payload;
      })
      .addCase(fetchShopStatus.rejected, (state, action) => {
        state.status = action.payload;
      });
  },
});

export const selectShopStatus = (state) => state.shopStatus.status;
export default shopStatusSlice.reducer;
