import React from "react";

const IconButton = ({width, height, backgroundPosition, backgroundSize}) => {
  const iconStyle = {
    width,
    height,
    backgroundSize,
    backgroundPosition,
    display: "block",
  };

  return <span style={{...iconStyle}} className="sprite"></span>;
};

export default IconButton;
