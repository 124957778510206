import { off, onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { rdb } from '../../firebaseConfig';
import NavBar from '../../Components/NavBar';
import { useNavigate } from 'react-router-dom';
import CategoryCard from '../../Components/CategoryCard';
import useCategories from '../../FirebaseMethods/GetCatogories';

function CategoriesPages() {
    const categoryData = useCategories()  
    const navigate = useNavigate();
    const handleCardClick = (id) => {
      navigate(`/${id}`);
    };
    
  
  return (
 
    <>
      <NavBar />

      <h2 className="text-2xl text-center font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
      Vår Meny
      </h2>

      <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-4 p-3 mt-4 text-white">
        {categoryData.map((item, index) => (
          
      <div
      className="max-w-sm rounded overflow-hidden  "
      key={index}
      onClick={() => {
        handleCardClick(item.id);
      }}
    >        
        <CategoryCard item={item}/>      
    </div>
        ))}
      </div>
    </>)
}

export default CategoriesPages