import NavBar from "../Components/NavBar";
import IconButton from "../Components/IconStyle";
import CartSection from "./CartSection";
import { translate } from "../util/TranslateFunction";
import { redirect, useNavigate } from "react-router-dom";

function Cart() {
   const navigate = useNavigate()
  const handleBackButton = ()=>{
    navigate("/menus")
  }
  

    return(<>
    <NavBar />
    <div className="px-2">
    <div className="flex mt-[20px] ">
        <button
          type="button"
          onClick={handleBackButton}
          className="focus:outline-none ml-1 px-3 text-white bg-[#eb6700] hover:bg-[#eb6700] h-[34px]  justify-center items-center font-medium rounded-[12px] text-sm p-1 absolute  "
        >
          <IconButton
            width="15px"
            height="15px"

            backgroundPosition="-728px 725px"
          />
        </button>
        <h2 className=" text-2xl text-[#14171A] flex-1 text-[26px] text-center mr-[45px] font-bold leading-7 sm:truncate sm:text-3xl sm:tracking-tight">
        {translate("cart")}
        </h2>
      </div>
      <div className="px-6">
      <CartSection />

      </div>
    </div>
    </>)
}

export default Cart;