// export default useGetAddons;
import { ref, get } from 'firebase/database';
import { rdb } from '../firebaseConfig';

// Function to get addOn products
export async function getaddOnProducts() {
 
  const addOnProductsRef = ref(rdb, 'addOn_products');

  try {
    const snapshot = await get(addOnProductsRef);
    const response = snapshot.val() || {};
    const addOnProductKeys= Object.keys(response)
   
 
    
    if(addOnProductKeys.length<=0)  return []
    // Retrieve addOn product details
    const addOnProducts = await Promise.all(
      addOnProductKeys.map(async (key) => {
        const productRef = ref(rdb, `products/${key}`);
        const productSnapshot = await get(productRef);
        return {id:key, ...productSnapshot.val()};
      })
    );

    return addOnProducts;
  } catch (error) {
    console.error('Error getting addOn products:', error);
    return [];
  }
};



