import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { selectShopStatus } from '../store/shopStatusSlice';

function TopNotificationBar() {
    const info = useSelector(selectShopStatus);
   
    const [message, setMessage] = useState("")
    useEffect(()=>{
    if(!info?.Bit){ setMessage("We are closed today 🔒") }
    else{
        if(!info?.isShopOpen){ setMessage("We are close right now 🔒")}
        else{
            setMessage("")
        }
    }
 
 
 

    },[info])
  return (
    info?.isShopOpen== false || info?.Bit == false?
    <div className='h-15 z-10 top-0 sticky '><p className='pt-5   align-text-bottom text-center font-semibold text-white bg-red-600'>{message}</p></div>:<></>

  
 
  )
}

export default TopNotificationBar