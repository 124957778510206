import React, { useEffect, useState } from "react";
import OptionsForm from "./OptionsForm";

const OptionsModal = ({ open, setOpen, item }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "scroll";
    }
  },[open]);

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div
      id="select-modal"
      tabIndex="-1"
      aria-hidden={!open}
      className={`overflow-y-auto overflow-x-hidden fixed  bg-[#0006] flex justify-center align-middle  z-50  items-center w-full inset-0 h-[calc(100%-1rem)] max-h-full ${
        open ? "" : "hidden"
      }`}
    >
      <div className="relative p-2 w-full max-w-md max-h-full ">
        <div className="relative bg-white rounded-lg shadow ">
          <div className="flex items-start justify-between p-4 md:p-4 border-b rounded-t dark:border-gray-600">
           
          <div >
            
            <h3 className="text-lg font-semibold text-gray-900 ">
              {item.name}
            </h3>
            <h3 className="text-sm font-semibold text-gray-600 ">
              {item.description}
            </h3>
            <h3 className="text-xs font-semibold text-black ">
              Select Options
            </h3>
           </div>
            <button
              onClick={handleCancel}
              type="button"
              className="text-gray-400 bg-transparent "
            >
              <svg
                className="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 text-black md:p-5">
            {item?.groups.length > 0 && open && (
              <OptionsForm item={item} closeModal={handleCancel} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OptionsModal;
