const cartItemPrice = (item) => {
    const price = item.price;
    let optionsPriceSum = 0;
    item?.options?.forEach((option) => {
      option?.selectedOptions?.forEach((element) => {
      
        optionsPriceSum += element.quantity * element.price;
      });
    });
 

    return (price + optionsPriceSum) * item.quantity;
  };
export default cartItemPrice