import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
// #fb692a
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'sv',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
    
   
      sv: {
        translation: {
          cart:"Varukorg",
          add_to_cart:"Lägg till",
          order_now: "Beställ nu",
          delivery: "Delivery",

          takeAway: "Take Away",
          total: "Totalt",
          inclusive_VAT: "Inklusive 12% moms",
          next: "Nästa",
          checkout: "Kassan",
          continue: "Fortsätt",
          no_product:"Ingen Produkt"
        }
    } ,
      en: {
      translation: {
        cart:"Cart",
        add_to_cart:"Add to cart",
        order_now: "Order Now",
        takeAway: "Take Away",
        delivery: "Delivery",
        total: "Total",
        inclusive_VAT: "Inclusive 12% VAT",
        next: "Next",
        checkout:"CheckOut",
        continue: "Continue"  ,
        no_product:"No Product"

      }
    }
   
    
    
  
   
     
    
    }
  });

export default i18n;