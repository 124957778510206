import React, { useEffect, useState } from "react";
import NavBar from "../Components/NavBar";
import IconButton from "../Components/IconStyle";
import {useLocation, useNavigate} from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../store/cartSlice";
import DishImage from "../Assets/Images/dish.svg"
import { getaddOnProducts } from "../FirebaseMethods/GetAddOns";
import { ImageUrl, rdb } from "../firebaseConfig";
import { useTranslation } from "react-i18next";
import Loader from "../Components/Loader";
import { generateUidWithTime } from "../util/GenerateUid";
import OptionsModal from "../Components/OptionsModal";
import { onValue, ref } from "firebase/database";
// import { translate } from "../util/TranslateFunction";



function AddOns() {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const [quantities, setQuantities] = useState({});
  const [products, setProducts] = useState([])
  const [open, setOpen] = useState(false);

  const getSnapshot = async (ref) => {
    const snapshot = await new Promise((resolve, reject) => {
      onValue(
        ref,
        (snapshot) => {
          resolve(snapshot);
        },
        {
          onlyOnce: true,
        }
      );
    });
    return snapshot;
  };
  useEffect(()=>{
  const addOns =  getaddOnProducts()
   addOns.then(async (result)=>{
    

    const fetchGroupDataForProducts = async (products) => {
      
      try {
        const updatedProducts = await Promise.all(
          products.map(async (product) => {
            
            const groupIds = product.groupIds || [];
            const groupData = await fetchGroupData(groupIds);
            return { ...product, groups: groupData };
          })
        );
        return updatedProducts;
      } catch (error) {
        console.error("Error fetching group data for products:", error);
        return [];
      }
    };
  
    const fetchGroupData = async (groupIds) => {
      try {
        const groupsPromises = groupIds.map((groupId) => {
          const groupRef = ref(rdb, `groups/${groupId}`);
        
          return getSnapshot(groupRef);
        });
        const groupSnapshots = await Promise.all(groupsPromises);
        return groupSnapshots.map((snapshot) =>{return {id: snapshot.key ,...snapshot.val()}});
      } catch (error) {
        console.error("Error fetching group data:", error);
        return [];
      }
    };
  const items = await  fetchGroupDataForProducts(result)
 
    
    console.log(result); setProducts(items)})
  },[])
 

  const handleAddToCart = (item, id) => {
    if(item.groupIds){   
      showModal()
      return
    }
    const cartItem = {cartItemKey:generateUidWithTime(), id, ...item, quantity: quantities[item.id] || 1 , options:[] };
    
    dispatch(addToCart(cartItem));
    setQuantities((prevQuantities) => ({ ...prevQuantities, [item.id]: 1 }));
  };
  
  const handlNextClick =(item) =>{
    navigate('/details');
  }
  const showModal = () => {
    setOpen(true);
    document.body.style.overflow = 'hidden';
  };
  return (
    <>
      <NavBar /> 
      <div className="flex p-5 w-full align-middle items-center">
        <button
          type="button"
          onClick={() => {
            navigate("/menus");
          }}
          className="focus:outline-none ml-5 px-2 text-white bg-[#eb6700] hover:bg-[#eb6700] h-[28px]  font-medium rounded-lg text-sm p-1  "
        >
          <IconButton
            width="15px"
            height="15px"
            backgroundPosition="-728px 725px"
          />
        </button> 
        <div className=" w-40">
       < p className=" text-2xl font-bold px-5 ">Add On</p>
            </div>
        <div className="flex w-full justify-end">
      <button onClick={()=>{
handlNextClick()
      }} className="flex justify-center items-center rounded-full my-[40px] bg-[#eb6700] py-[15px] px-[40px] text-white text-center font-syne text-base font-medium leading-normal">
          {t('next')}
</button>
</div>
      
      </div>

      {Object.keys(products).length==0?<p className=" font-bold mt-5 text-3xl text-center ">{t('no_product')}</p>:null}
      <div className="grid  md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 px-3  text-white">
 
   
      {products?.map((item, index) => (
         item?.active ?
         <>
      {item.downloadToken ? (
        <div
          className="bg-[#eeeeee]  border shadow-lg hover:scale-105 duration-300  rounded-lg"

        > <div className="w-full bg-red-500  relative">
           {item.is_out_of_stock && (
                <span className="bg-red-100 absolute right-0  text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                  Out Of Stock
                </span>
              )} 

        </div>
       
        <div className=" h-36 overflow-hidden">
          <Loader imageUrl={ImageUrl+item.downloadToken}  alt="Food pic"/>
          </div>
          <span className="ml-[13px] bg-black text-[#eb6700] font-epilogue font-medium text-base flex py-1 px-4 mt-[-16px] absolute w-fit rounded-full shadow-[5px_10px_20px_0_rgba(30,43,33,0.19)]">
            Kr {item.price}
          </span>

          <div className="flex gap-2 flex-col mt-[20px] px-[13px] pb-[8px]">
            <p className="text-[#eb6700] font-syne text-18.214 font-normal">
              {item.name}
            </p>
            <p className="text-black my-3 font-epilogue text-xs font-normal line-clamp-2">
              {item?.description}
            </p>
          </div>

          <div className="p-3 text-right ">
          <button
                disabled={item.is_out_of_stock}
                onClick={() => {
                  handleAddToCart(item);
                }}
                className="text-white  bg-[#eb6700] hover:bg-[#f06a31] disabled:bg-[#b87558] disabled:cursor-not-allowed disabled:text-[#a1945c] font-medium rounded-lg text-sm px-2  md:px-5 py-2.5 text-center"
              >
               {t("add_to_cart")}
              </button>
          </div>
        </div>
      ) : (
       
          <div className=" flex h-full flex-col justify-between w-full select-none  max-w-sm  border bg-[#eeeeee] rounded-lg shadow  p-4 md:p-3  hover:scale-105 duration-300 cursor-pointer">
            <div className="flex justify-between">
              <span>
                <img src={DishImage} alt="burger icon" />
              </span>
              {item.is_out_of_stock && (
                <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                  Out Of Stock
                </span>
              )}
            </div>
            <div
              className="flex gap-4 flex-col "
            
            >
              <h5 className="text-xl font-semibold tracking-tight text-black capitalize">
                {item.name}
              </h5>
              <p className="text-black my-3 font-epilogue text-xs font-normal line-clamp-2">
                {item.description}
              </p>
            </div>

            <div className="flex items-center text-black justify-between">
              <span>
                Kr{" "}
                <span className="text-2xl font-bold  text-[#eb6700] ">
                  {item.price}
                </span>
              </span>

              <button
                disabled={item.is_out_of_stock}
                onClick={() => {
                  handleAddToCart(item);
                }}
                className="text-white   bg-[#eb6700] hover:bg-[#f06a31] disabled:bg-[#b87558] disabled:cursor-not-allowed disabled:text-[#a1945c] font-medium rounded-lg text-sm px-2  md:px-5 py-2.5 text-center"
              >
               {t("add_to_cart")}
              </button>
            </div>
          </div>
      
      )}
      {/* <!-- Modal toggle --> */}
      <OptionsModal open={open} setOpen={setOpen} item={item}/>
    

    </>:<div>No Addons</div>
      )) }
      </div>
     
    </>
  );
}

export default AddOns;
