// userSlice.js
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: {
      email: '',
      name: '',
      phoneNumber: '',
      
      
    },
    address:"",
    deliveryCharge:0
    
  },
  reducers: {
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setAddressToState: (state, action) => {
      state.address = action.payload;
    },
    setDeliveryCharge: (state, action) => {
      state.deliveryCharge = action.payload;
    },
  },
});

export const { setUserInfo, setDeliveryCharge, setAddressToState } = userSlice.actions;
export default userSlice.reducer;
