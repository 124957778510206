import React from "react";
import defaultOurMenu from "../Assets/icons/iconForCategoryBar.png";
import "./OurMenusBar.css";
import {useNavigate} from "react-router-dom";
import useCategories from "../FirebaseMethods/GetCatogories";
import { ImageUrl } from "../firebaseConfig";

import Loader from "./ImageLoader";

function OurMenusBar() {
  const navigate = useNavigate();
  const categories = useCategories();

 
  const handleClick = (item,name) => {
    navigate(`/${item}`, {name});
  };
  return (
    <>
      <div className=" p-3  scrollBarStyle pt-3 bg-[#FCFCFC] align-middle  flex gap-4 overflow-auto  px-5">
      
        {categories.map((item, index) => (
     
          <div
            key={item.id}
            onClick={() => {
              handleClick(item.id, item.name);
            }}
            className="p-2 px-6 w-full   shadow-md flex flex-col  align-middle  items-center cursor-pointer"
          > 
               {item.imageDownloadToken &&  <div className=" h-14 w-14 rounded-full overflow-hidden">
      
              <Loader imageUrl={ImageUrl+item.imageDownloadToken} alt="Unable to load in barSection"/>
                     </div> }
          

            <p className=" w-full pt-3 line-clamp-2  text-center text-[10px] md:text-[12px] ">
              {item.name}
            </p>
          </div>
        ))}

      </div>
      
    </>
  );
}

export default OurMenusBar;
