import React, { useState } from "react";
import DishImage from "../Assets/Images/dish.svg";
import { addToCart } from "../store/cartSlice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ImageUrl } from "../firebaseConfig";
import Loader from "./ImageLoader";
import { translate } from "../util/TranslateFunction";
import OptionsModal from "./OptionsModal";
import { generateUidWithTime } from "../util/GenerateUid";
function MenuItemCard({ item }) {

  const [quantities, setQuantities] = useState({});
  const [open, setOpen] = useState(false);

  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleAddToCart = (item, id) => {
    if(item.groupIds){   
      showModal()
      return
    }
    const cartItem = {cartItemKey:generateUidWithTime(), id, ...item, quantity: quantities[item.id] || 1 , options:[] };
    
    dispatch(addToCart(cartItem));
    setQuantities((prevQuantities) => ({ ...prevQuantities, [item.id]: 1 }));
  };
  const handleMenuItemClick = (item) => {
    navigate(`${location.pathname}/addToCart`, {
      state: { id: item, details: item },
    });
  };
  const showModal = () => {
    setOpen(true);
    document.body.style.overflow = 'hidden';
  };

  return (
    <>
      {item.downloadToken ? (
        <div
          className="bg-[#eeeeee]  border shadow-lg hover:scale-105 duration-300  rounded-lg"

        > <div className="w-full bg-red-500  relative">
           {item.is_out_of_stock && (
                <span className="bg-red-100 absolute right-0  text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                  Out Of Stock
                </span>
              )} 

        </div>
       
        <div className=" h-36 overflow-hidden" onClick={() => handleMenuItemClick(item)}>
          <Loader imageUrl={ImageUrl+item.downloadToken}  alt="Food pic"/>
          </div>
          <span className="ml-[13px] bg-black text-[#eb6700] font-epilogue font-medium text-base flex py-1 px-4 mt-[-16px] absolute w-fit rounded-full shadow-[5px_10px_20px_0_rgba(30,43,33,0.19)]">
            Kr {item.price}
          </span>

          <div className="flex gap-2 flex-col mt-[20px] px-[13px] pb-[8px]">
            <p className="text-[#eb6700] font-syne text-18.214 font-normal">
              {item.name}
            </p>
            <p className="text-black my-3 font-epilogue text-xs font-normal line-clamp-2">
              {item?.description}
            </p>
          </div>

          <div className="p-3 text-right ">
          <button
                disabled={item.is_out_of_stock}
                onClick={() => {
                  handleAddToCart(item);
                }}
                className="text-white  bg-[#eb6700] hover:bg-[#f06a31] disabled:bg-[#b87558] disabled:cursor-not-allowed disabled:text-[#a1945c] font-medium rounded-lg text-sm px-2  md:px-5 py-2.5 text-center"
              >
               {translate("add_to_cart")}
              </button>
          </div>
        </div>
      ) : (
       
          <div className=" flex h-full flex-col justify-between w-full select-none  max-w-sm  border bg-[#eeeeee] rounded-lg shadow  p-4 md:p-3  hover:scale-105 duration-300 cursor-pointer">
            <div className="flex justify-between">
              <span>
                <img src={DishImage} alt="burger icon" />
              </span>
              {item.is_out_of_stock && (
                <span className="bg-red-100 text-red-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                  Out Of Stock
                </span>
              )}
            </div>
            <div
              className="flex gap-4 flex-col "
              onClick={() => {
                handleMenuItemClick(item);
              }}
            >
              <h5 className="text-xl font-semibold tracking-tight text-black capitalize">
                {item.name}
              </h5>
              <p className="text-black my-3 font-epilogue text-xs font-normal line-clamp-2">
                {item.description}
              </p>
            </div>

            <div className="flex items-center text-black justify-between">
              <span>
                Kr{" "}
                <span className="text-2xl font-bold  text-[#eb6700] ">
                  {item.price}
                </span>
              </span>

              <button
                disabled={item.is_out_of_stock}
                onClick={() => {
                  handleAddToCart(item);
                }}
                className="text-white   bg-[#eb6700] hover:bg-[#f06a31] disabled:bg-[#b87558] disabled:cursor-not-allowed disabled:text-[#a1945c] font-medium rounded-lg text-sm px-2  md:px-5 py-2.5 text-center"
              >
               {translate("add_to_cart")}
              </button>
            </div>
          </div>
      
      )}
      {/* <!-- Modal toggle --> */}
    
      <OptionsModal open={open} setOpen={setOpen} item={item}/>

    </>
  );
}

export default MenuItemCard;
