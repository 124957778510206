import { useNavigate } from "react-router-dom";
import DishImage from "../Assets/Images/dish.svg";

import IconButton from "../Components/IconStyle";
import { useDispatch, useSelector } from "react-redux";
import { changeQuantity, removeFromCart } from "../store/cartSlice";
import { ImageUrl } from "../firebaseConfig";
import { useTranslation } from "react-i18next";

function CartSection() {
  const cartItems = useSelector((state) => state.cart.items);
  const subtotal = useSelector((state) => state.cart.subTotal);
  const vat = useSelector((state) => state.cart.VAT);
  console.log(cartItems);
  const { t } = useTranslation();
  const dispatch = useDispatch();


  const handleRemoveItem = (itemId) => {
    dispatch(removeFromCart({ cartItemKey: itemId }));
  };

  const handleChangeQuantity = (itemId, newQuantity) => {
    dispatch(changeQuantity({ id: itemId, quantity: newQuantity }));
  };

  const isItems = cartItems?.length;

  const navigate = useNavigate();


  const cartItemPrice = (item)=>{
   const price = item.price;
   let optionsPriceSum = 0;
   item?.options?.forEach(option => {
    option?.selectedOptions?.forEach(element => {
      console.log(element);
      optionsPriceSum += element.quantity*element.price
     });
   });
   console.log(price, optionsPriceSum);
  
   return (price + optionsPriceSum)*item.quantity

  }

  
  

  return (
    <>
      {isItems === 0 ? (
        <p className="text-2xl font-bold text-center p-5">{t("no_product")}</p>
      ) : (
        <div className="flex flex-col gap-[24px] mt-[30px] h-[230px] overflow-y-auto">
          {cartItems?.map((item, index) => (
            <div
              key={index}
              className="flex justify-between items-start gap-[14px]"
            >
              <div className="flex gap-10">
                {item.downloadToken && (
                  <img
                    src={`${ImageUrl}${item.downloadToken}`}
                    alt="_food"
                    className="rounded-[18px] w-[82px] h-[83px]"
                  />
                )}
                {!item.downloadToken && (
                 <div className=" shrink-0">
                    <img
                      src={DishImage}
                      alt="_food"
                      height={50}
                      width={50}
                      className="rounded-[18px] shrink-0"
                    />
                  </div>
                )}

                <div className="flex flex-col gap-[14px]">
                  <p className="text-black font-epilogue font-bold text-base  leading-normal">
                    {item.name}
                  </p>
                  <p className="text-[#FE724C] font-epilogue text-base font-medium leading-normal">
                    Kr {cartItemPrice(item)}
                  </p>
                  {item.options &&
                    item.options.map((option, index) => (
                      <div key={"option_" + index}>
                       
                        <span className=" font-semibold">{option.group_name}:</span>
                        <div className="flex flex-col px-3">
                         
                          {option.selectedOptions.map(
                            (selectedOption, index) => (
                              <span key={"so" + index}>
                                {selectedOption.quantity} x {selectedOption.name}
                              </span>
                            )
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {/* remove item cross button */}
              <div className="hidden flex-col justify-between gap-2 pr-[20px] md:flex">
                <div
                  className="rounded-full flex flex-row-reverse cursor-pointer  items-center"
                  onClick={() => handleRemoveItem(item.cartItemKey)}
                >
                  <IconButton
                    width="30px"
                    height="30px"
                    backgroundSize="765px"
                    backgroundPosition="-124px 652px"
                  />
                </div>
                {/* Quantity starts */}
                <div className="flex gap-2  justify-center items-center ">
                  <span className="text-xs  text-gray-400">Qyt: </span>
                  <div
                    className="text-[#eb6700] border border-[#eb6700] pb-[0px] px-2 rounded-full select-none cursor-pointer"
                    onClick={() => {
                      if (item.quantity > 1)
                        handleChangeQuantity(
                          item.cartItemKey,
                          item.quantity - 1
                        );
                    }}
                  >
                    -
                  </div>
                  <span className="text-[#5B5B5E] font-epilogue text-base font-normal leading-6">
                    {item.quantity}
                  </span>{" "}
                  <div
                    className="text-white bg-[#eb6700] pt-[0px] pb-[2px] px-2 rounded-full select-none cursor-pointer"
                    onClick={() =>
                      handleChangeQuantity(item.cartItemKey, item.quantity + 1)
                    }
                  >
                    +
                  </div>
                </div>
                {/* Quantity ends */}
              </div>
            </div>
          ))}
        </div>
      )}
      <hr className="mt-[44px]" />

      <div className="flex flex-col px-[12px] py-[24px] bg-[#f5f6f7] rounded-[8px] mt-[36px]">
        <div className="flex justify-between items-center py-[16px] border-b border-[#F1F2F3]">
          <p className="text-[#14171A] font-epilogue text-base font-normal leading-normal">
            {t("total")}
          </p>
          <p className="text-[#14171A] text-right font-epilogue text-base font-normal leading-normal">
            Kr {subtotal.toFixed(2)}
          </p>
        </div>
        <div className="flex justify-between items-center py-[16px] border-b border-[#F1F2F3]">
          <p className="text-[#14171A] font-epilogue text-base font-normal leading-normal">
            {t("inclusive_VAT")}
          </p>
          <p className="text-[#14171A] text-right font-epilogue text-base font-normal leading-normal">
            Kr {vat.toFixed(2)}
          </p>
        </div>
        <button
          disabled={!isItems}
          onClick={() => navigate("/add_ons")}
          className="flex justify-center items-center rounded-full my-[40px] bg-[#eb6700] py-[15px] px-[40px] text-white text-center font-syne text-base font-medium leading-normal"
        >
          {t("continue")}
        </button>
      </div>
    </>
  );
}

export default CartSection;
