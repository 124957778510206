import React, { useState } from "react";
import NavBar from "../Components/NavBar";
import { Input } from "antd";
import { translate } from "../util/TranslateFunction";
import MapModal from "../Components/MapModal";

function Address() {
  const [address, setAddress] = useState("");
  const [open, setOpen] = useState(false);
  const [mapCenter, setMapCenter] = useState({
    lat: 29.1136711,
    lng: 75.8144037,
  });
  const [formData, setFormData] = useState({street:"", town:"", postcode:"" });
  const [errors, setErrors] = useState({});

  const getCodes = async (formData) => {
    const addressString = formData.street +" "+ formData.town+" " + formData.postcode
    function formatAddress(address) {
      // Replace symbols other than '+', ',' and '-' with an empty string
      let formattedAddress = address.replace(/[^\+\,\-\w\s]/g, "");

      // Replace spaces with '+'
      formattedAddress = formattedAddress.replace(/\s/g, "+");

      return formattedAddress;
    }
    const formatedAddress = formatAddress(addressString);
   
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${formatedAddress}&key=${process.env.REACT_APP_GoogleMapApi}`
      );
      const data = await response.json();
      console.log("$$$$$$$$$4",data);
      if (data.results && data.results.length > 0) {
        setMapCenter(data.results[0].geometry.location);
     setAddress(addressString)
        
        setOpen(true)
      }
    } catch (error) {
      console.error("Error fetching address and distance:", error);
    }
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate name
    if (!formData.street.trim()) {
      newErrors.street = "Street or Number is required";
      isValid = false;
    }

    if (!formData.town.trim()) {
      newErrors.town = "Town or City Required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      getCodes(formData)
    } else {
      console.log("Form has validation errors");
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <NavBar />
      

      <form onSubmit={handleSubmit}>
        <div className="flex flex-col gap-[12px] p-6">
      <h3 className="font-xl">Enter Address</h3>

          <div className="flex flex-col gap-[6px]">
            <Input
              type="text"
              id="street"
              name="street"
              value={formData.street}
              onChange={handleChange}
              placeholder="Street Name or Number"
            />
            {errors.street && (
              <span className=" text-red-400">{errors.street}</span>
            )}
          </div>
          <div className="flex flex-col gap-[6px]">
            <Input
              type="text"
              id="town"
              name="town"
              value={formData.town}
              onChange={handleChange}
              placeholder="town or near city"
            />
            {errors.town && <span className="text-red-400">{errors.town}</span>}
          </div>
          <div className="flex flex-col gap-[6px]">
            <Input
              type="text"
              id="postcode"
              name="postcode"
              value={formData.email}
              onChange={handleChange}
              placeholder="Postcode, Block, Intercom, Where to Park"
            />
          </div>
          <div className="flex justify-center items-center">
            <button
              type="submit"
              className="flex justify-center items-center rounded-full my-[40px] bg-[#eb6700] py-[15px] px-[40px] text-white text-center font-syne text-base font-medium leading-normal"
            >
              Find
            </button>
          </div>
        </div>
      </form>
      <MapModal open={open} setOpen={setOpen} mapCenter={mapCenter} address={address} setAddress={setAddress}/>
   
    </>
  );
}

export default Address;
