import React, { useEffect, useState } from "react";
import { httpsCallable } from "firebase/functions";
import { firebasefunctions } from "../firebaseConfig";
import Spiner from "../Components/Spiner";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearCart } from "../store/cartSlice";
const CheckOut = () => {
  const [loading, setloading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderDetails, setOrderDetails] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const OrginalCart = useSelector((state) => state.cart.items);
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user);

  const cartItem = {cart, user}
  const CartItems = OrginalCart.map(({ id, quantity }) => ({
    productId: id,
    quantity,
  }));
  const cartId= localStorage.getItem("CID");
  const oldPaymentId = localStorage.getItem("PID")
  

  const handleClearCart = () => {
    dispatch(clearCart());
  };
  

  
  useEffect(() => {
    if (Object.keys(CartItems).length <= 0 && !cartId ) {
      navigate("/menus");
      return;
    }
    if(oldPaymentId){
     
      setPaymentId(oldPaymentId);
      setloading(false)
      
    }
    window.addEventListener('popstate', (e) => {
      window.history.go(1);
    });
   
    if(!oldPaymentId){
      const CreatePaymentApi = httpsCallable(firebasefunctions, "CreatePaymentApi");
      CreatePaymentApi({ cartItem  })
        .then((response) => {
                const data = response.data.result;
        
                if (!data.PID) {
                  console.error("Error: Check output from create-payment");
                  return;
                }
                localStorage.setItem("PID",data.PID)
                localStorage.setItem("CID",data.CID)
                localStorage.setItem("orderNumber", data.orderUiqNumber)
                setPaymentId(data.PID);
                setOrderDetails(data.orderUiqNumber);
                setloading(false);
        })
        .catch((error) => {
          console.log(error);
         alert("Unable to create Payment")
        });
    }
  
  }, []);


  useEffect(() => {
    if (paymentId) {
      try {
        const loadCheckoutScript = () => {
          return new Promise((resolve, reject) => {
            const scriptExist = document.getElementById("scriptLoaded");
            if (scriptExist) {
              resolve();
              return;
            }
            const checkoutScript = document.createElement("script");
            checkoutScript.src = process.env.REACT_APP_Checkout_ScriptURL
            checkoutScript.async = true;
            checkoutScript.id = "scriptLoaded";
            checkoutScript.onload = resolve;
            checkoutScript.onerror = reject;
            document.body.appendChild(checkoutScript);
          });
        };

        loadCheckoutScript()
          .then(() => {
            const k = process.env.REACT_APP_CheckoutKey;

            if (paymentId) {
              const checkoutOptions = {
                checkoutKey: k,
                paymentId: paymentId,
                containerId: "checkout-container-div",
              };
              // eslint-disable-next-line no-undef
              const checkout = new Dibs.Checkout(checkoutOptions);
              checkout.on("payment-completed", async function (data) {
                try {
                  handleClearCart();
                  navigate("/orderConfirmed", {
                    state: { orderDetails },
                    replace: true,
                  });
                  localStorage.removeItem("PID")
                  localStorage.removeItem("CID")
                
                } catch (error) {
                  console.log("error", error);
                }
              });
            } else {
              console.log("Expected a paymentId");
            }
          })
          .catch((error) => {
            console.error("Error loading scripts:", error);
          });
      } catch (error) {
        console.log("Error in fetching scripts", error);
      }
    }

    // Cleanup on unmount
    return () => {
      setPaymentId("");

      // You may choose to remove scripts on unmount, but it's not strictly necessary
    };
  }, [paymentId]);

  return (
    <div>
     
      {loading ? (
        <div className=" w-screen h-screen flex align-middle items-center justify-center">
          <Spiner />
        </div>
      ) : (
        <div id="checkout-container-div"></div>
      )}
    </div>
  );
};

export default CheckOut;
