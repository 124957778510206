import {Route, Routes} from "react-router-dom";
import "./App.css";

import LandingPage from "./Pages/LandingPage";
import OurMenu from "./Pages/OurMenu";
import MenuItems from "./Pages/MenuItems";
import AddToCart from "./Pages/AddToCart"
import Cart from "./Pages/Cart"
import Details from "./Pages/Details"
import Order from "./Pages/Order"
import SignUp from "./Pages/SignUp"
import OtpPage from "./Pages/OtpPage"
import Slider from "./Pages/Slider"
import Orders from "./Pages/Orders";
import Login from "./Pages/Login";
import { Provider, useDispatch } from "react-redux";
import { store } from "./store/store";
import AddOns from "./Pages/AddOns";
import CheckOut from "./Pages/CheckOut";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import { useEffect } from "react";
import { fetchShopStatus } from "./store/shopStatusSlice";
import TopNotificationBar from "./Components/TopNotificationBar";
import CookiesPolicy from "./Pages/CookiesPolicy";
import PurchasePersonalDataController from "./Pages/PurchasePersonalDataController";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import CategoriesPages from "./Pages/newpages/CategoriesPages";
import ProductsPage from "./Pages/newpages/ProductsPage";
import TermsAndConditions from "./Pages/TermsAndConditions";
import GoogleMap from "./Components/GoogleMap";
import Address from "./Pages/Address";
import OrderSummary from "./Pages/OrderSummery";
import DeleteAccountRequest from "./Pages/DeleteAccountRequest";

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
     dispatch(fetchShopStatus());
  }, [dispatch]);
  return (
    <Provider store={store}>
      <ToastContainer />
      <TopNotificationBar/>
      
    <Routes>
      <Route path="*" element={<LandingPage />} />
      {/* <Route path="/menus" element={<OurMenu />} /> */}
      <Route path="/menus" element={<CategoriesPages />} />
      {/* <Route path="/:id" element={<MenuItems />} /> */}
      <Route path="/:id" element={<ProductsPage />} />
      <Route path="/order-summary" element={<OrderSummary />} />
      <Route path="/:id/addToCart" element={<AddToCart />} />
      <Route path="/details/address" element={<Address />} />
      <Route path="/cart" element={<Cart />} />
      <Route path="/checkout" element={<CheckOut />} /> 
      <Route path="/details" element={<Details />} />
      <Route path="/orderConfirmed" element={<Order />} />
      {/* <Route path="/signUp" element={<SignUp />} /> */}
      <Route path="/login" element={<Login />} />
      {/* <Route path="/signUp/otp" element={<OtpPage />} /> */}
      {/* <Route path="/signUp/otp/towardsMenu" element={<Slider />} /> */}
      {/* <Route path="/orders" element={<Orders />} /> */}
      <Route path="/add_ons" element={<AddOns />} /> 
      <Route path="/test" element={<GoogleMap />} /> 
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/cookies-policy" element={<CookiesPolicy />} />
      <Route path="/purchase-policy" element={< PurchasePersonalDataController/>} />
      <Route path="/terms-and-conditions" element={< TermsAndConditions/>} />
      <Route path="/delete-account-request" element={< DeleteAccountRequest/>} />

    </Routes>
    </Provider>
  );
}

export default App;
